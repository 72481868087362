// Swup
let options = {
    plugins: [
        new SwupScrollPlugin({
            doScrollingRightAway: false,
            animateScroll: false,
        }),
        new SwupGaPlugin(),
        // new SwupDebugPlugin()
    ]
};

const swup = new Swup(options);

swup.on('contentReplaced', reloadScripts);

// swup reloads
function reloadScripts() {
    setTimeout(() => {
        initRoyalSlider();
        AOS.refreshHard();
    }, 100);    // setTimeout here so images have some time to load before AOS kicks in
}

// Royal Slider
function initRoyalSlider() {
    jQuery(document).ready(function ($) {
        $(".royalSlider").royalSlider({
            // options go here
            // as an example, enable keyboard arrows nav
            keyboardNavEnabled: false,
            autoHeight: false,
            autoScaleSlider: false,
            imageScaleMode: 'none',
                        randomizeSlides:true,
            imageAlignCenter: false,
            arrowsNav: false,
            slidesSpacing: 0,
            transitionType: 'fade',
            transitionSpeed: 900,
            navigateByClick: false,
            slidesOrientation: 'horizontal',
            loop: true,
            allowCSS3: true,
            controlNavigation: 'none',
            autoplay: {
                // autoplay options go gere
                enabled: true,
                delay: 900,
                pauseOnHover: false,
            },
        });
    });
}





// run these after page is loaded
initRoyalSlider();

document.addEventListener("DOMContentLoaded", function () {
    yall({
        observeChanges: true,
        threshold: 3000,
        
    });
});

AOS.init({
    // Global settings:
    disable: 'phone', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 100, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 750, // values from 0 to 3000, with step 50ms
    easing: 'ease-in', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: true, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});







